<template>
  <div class="card">

    <div class="card-title bg-success-800 panel-title"  style="padding-left: 15px;padding-top: 10px;" >
      <h4>
        <span class="font-weight-semibold">Budget</span> Creation
      </h4>

    </div>


    <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold ">Branch</label>
            <select  id="cmbbudget" class="form-control select"  autofocus="" required="" v-if="budget"  v-model="budget.branch.code" >
              <option v-for="branch in branches" v-bind:value="branch.code">
                {{ branch.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-7">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Date</label>
            <input type="date" class="form-control" v-if="budget" v-model="budget.date">
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Project</label>
            <select class="form-control select" required="" v-if="budget"  v-model="budget.project.code" >
              <option v-for="project in projects" v-bind:value="project.code">
                {{ project.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-6"></div>

        <div class="col-md-1">
          <div class="form-group form-group-material">
            <label class=" control-label font-weight-semibold">Note No</label>
            <input type="number" class="form-control" placeholder="Note No" min="0" v-if="budget" v-model="budget.note_no">
          </div>
        </div>


        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label  font-weight-semibold">Note Date</label>
            <input type="date" class="form-control" v-if="budget" v-model="budget.note_date">
          </div>
        </div>
      </div>


      <div class="row">
        <!--  Detail Table -->
        <div class="table-responsive">

          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th >Group</th>
              <th style="width:200px; text-align: right;">Value</th>
              <th style="width: 30px;">Action</th>
            </tr>

            </thead>
            <tbody v-model="budget" v-for="(detail,index) in budget.list" >

            <tr>
              <td style="text-align: center;"> {{index + 1}} </td>
              <td >
                <select class="form-control" v-if="detail" v-model="detail.item_group.code" >
                  <option v-for="item in detailGroups" v-bind:value="item.code">
                    {{ item.name }}
                  </option>
                </select>
              </td>

              <td >
                <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.value"  @change="rowValueEqualizer(2,index)" />
              </td>


              <td class="text-align: center">
                <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
              </td>
            </tr>

            </tbody>
            <tfoot>
            <tr>
              <td>
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>


            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- / Detail Table -->


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="budget" v-model="budget.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">
          <h3 style="font-size: 48px;color: blue">
            &#8377;<span style="padding-right: 15px;">{{ indianFormat(rowAmountTotal) }}</span>
          </h3>
        </div>

        <div class="col-md-3 text-right">


          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'BudgetForm',
    components: {

    },
    props: {
      mybudget: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"type":0,"status":0,"fin_year":0,"date":"0001-01-01","branch":{"id":"","code":0,"name":""},"project":{"id":"","code":0,"type":0,"name":"","print_name":""},"note_no":"","note_date":"0001-01-01","remarks":"","list":[]}')
      },
      FinancialYear: {
        type: Number,
        default: 2000
      }
    },
    beforeMount () {
      this.budget = this.mybudget;
    },
    data () {
      return {
        readonly: false,
        branches:[],
        projects: [],
        detailGroups:[],
        groups : new Map(),
        rowAmountTotal: 0.0,
        budget: JSON.parse('{"id":"","code":0,"type":0,"status":0,"fin_year":0,"date":"0001-01-01","branch":{"id":"","code":0,"name":""},"project":{"id":"","code":0,"type":0,"name":"","print_name":""},"note_no":"","note_date":"0001-01-01","remarks":"","list":[]}'),
        seriesid: 1004
      }
    },
    created () {

    },
    mounted () {
      let self = this;
      self.loadData();

      self.$data.budget.date = moment().format('YYYY-MM-DD');
      self.$data.budget.note_date = moment().format('YYYY-MM-DD');

      $('.form-control-select2').select2();

      $('#cmbbudget').focus();
    },
    methods:{
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      addRow(){
        try {
          let self = this;

          self.$data.budget.list.push(JSON.parse('{"item_group":{"id":"","code":0,"nature":0,"name":""},"value":0}'));
          // var myobj = $('table>tbody>tr:last>td:nth-child(2)').children();
          // $(myobj).focus();

          self.$data.rowAmountTotal = 0.0;
          self.$data.budget.list.forEach(function (detail) {
            self.$data.rowAmountTotal += parseFloat(detail.value);
          });

        }catch (e) {
          alert(e);
        }
      },
      rowValueEqualizer(rowIndex, colIndex) {
        let self = this;

        let detail = self.$data.budget.list[rowIndex];
        self.$data.rowAmountTotal = 0.0;
        self.$data.budget.list.forEach(function (detail) {
          self.$data.rowAmountTotal += parseFloat(detail.value);
        });
      },

      clear(){
        let self = this;
        self.$data.budget = JSON.parse('{"id":"","code":0,"type":0,"status":0,"fin_year":0,"date":"0001-01-01","branch":{"id":"","code":0,"name":""},"project":{"id":"","code":0,"type":0,"name":"","print_name":""},"note_no":"","note_date":"0001-01-01","remarks":"","list":[]}');
        self.$data.budget.date = moment().format('YYYY-MM-DD');
        self.$data.budget.list = [];
        self.$data.rowAmountTotal = 0;
      },
      loadData() {
        let self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        //fetch the Ledgers
        self.$data.branches = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/branch/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            self.$data.branches = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

        //fetch the Projects
        self.$data.projects = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/project/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            self.$data.projects = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

        //Fetch Detail Ledgers
        self.$data.detailGroups = []
        self.$data.groups = new Map();
        fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/itemgroup/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            self.$data.detailGroups = resp.data;
            self.$data.detailGroups.forEach(function (grp) {
              self.$data.groups.set(grp.code, grp);
            });

          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      removeRow(index){
        if (index > -1) {
          let self = this;
          this.$data.budget.list.splice(index, 1);
          self.$data.rowAmountTotal = 0.0;
          self.$data.budget.list.forEach(function (detail) {
            self.$data.rowAmountTotal += parseFloat(detail.value);
          });

        }
      },
      saveVoucher(){
        let self = this;

        let mybudget =  JSON.parse(JSON.stringify(self.$data.budget));
        mybudget.fin_year = 2018;
        mybudget.date = moment(self.$data.budget.date).format('YYYY-MM-DD') + 'T00:00:00Z';
        mybudget.note_date = moment(self.$data.budget.note_date).format('YYYY-MM-DD') + 'T00:00:00Z';


        mybudget.list.forEach(function (detail){
          detail.value = parseFloat(detail.value);
        });


        const requestOptions = {
          method: 'POST',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(mybudget)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/ams/it/budget/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            swal({
              title: 'Great',
              type: 'success',
              text: resp.message
            });
            self.clear();
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      }
    }
  }
</script>

<style scoped>
  td {
    /*text-align: center;*/
    margin: 0 !important;
    padding:0 !important;
  }
  td input,select {
    display: block !important;
    padding: 0 !important;
    margin: 0 !important;
    /*border: 0 !important;*/
    width: 100% !important;
    height: 35px !important;
    border-radius: 0 !important;
    /*line-height: 1 !important;*/
  }

  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
